import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Analytics } from '@vercel/analytics/react';
import Head from 'next/head'
import eventConfig from "./../../public/event.config.json"

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{eventConfig.name}</title>
      </Head>
      <ToastContainer />
      <Component {...pageProps} />
      <Analytics />
    </>
  )
}
